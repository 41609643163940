import { GITHUB_LINK, TWITTER_LINK, MEDIUM_LINK, V1_APP_LINK, DISCORD_LINK } from '../../config/links'

export const Footer = () => {
  return (
    <footer className="w-full bg-bone px-8 mt-auto md:px-24 py-6 bg-bone-dark grid grid-cols-1 sm:grid-cols-3">
      <p className="text-center sm:text-left uppercase flex-none">Rysk - Crypto Uncorrelated Returns</p>
      <div className="text-center pt-10 sm:pt-0">
        <button
          onClick={() => {
            window.open(V1_APP_LINK)
          }}
        >
          Rysk v1
        </button>
        <button
          className="ml-4"
          onClick={() => {
            window.open(MEDIUM_LINK)
          }}
        >
          Blog
        </button>
        <button
          className="ml-4"
          onClick={() => {
            window.open('/press.zip')
          }}
        >
          Press kit
        </button>
      </div>
      <div className="text-center md:text-right pt-10 sm:pt-0">
        <button
          className="mr-4"
          onClick={() => {
            window.open(DISCORD_LINK)
          }}
        >
          <img src="/icons/discord.svg" className="w-6 h-6" />
        </button>
        <button
          className="mr-4"
          onClick={() => {
            window.open(TWITTER_LINK)
          }}
        >
          <img src="/icons/twitter.svg" className="w-6 h-6" />
        </button>
        <button
          onClick={() => {
            window.open(GITHUB_LINK)
          }}
        >
          <img src="/icons/github.svg" className="w-6 h-6" />
        </button>
      </div>
    </footer>
  )
}
