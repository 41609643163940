import Link from 'next/link'
import { Button } from '../shared/Button'
import { EARLY_ACCESS_LINK } from 'config/links'

export const Hero = () => {
  return (
    <div 
      className="w-full min-h-[calc(100vh-100px)] flex flex-col justify-center items-center px-8 md:px-48 bg-cover bg-center"
      style={{ backgroundImage: "url('/rysk_bg.webp')" }}
    >
      <div className="w-full md:w-full flex flex-col items-center space-y-12">
        <img src={'/rysk_v2_logo.svg'} alt="logo" className="h-48 md:h-64 lg:h-80 xl:h-96" />
        <h1 className="font-parabole text-[48px] md:text-[80px] md:leading-[96px] text-center mb-8 text-white">
          <>Crypto Uncorrelated Returns</>
        </h1>
        <Link href={EARLY_ACCESS_LINK} target="_blank" rel="noreferrer">
          <Button className="!py-4 !px-8">Get early access</Button>
        </Link>
      </div>
    </div>
  )
}