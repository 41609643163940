import type { ButtonHTMLAttributes, DetailedHTMLProps } from 'react'

type ButtonProps = DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>

export const Button = (props: ButtonProps) => {
  return (
    <button
      {...props}
      className={`border-black border-2 bg-white text-md px-2 py-2 hover:animate-border-round ${props.className ?? ''}`}
    />
  )
}
