import type { NextPage } from 'next'
import { useCallback, useState } from 'react'

// import { GLSLCanvas } from '../components/GLSLCanvas'
import { HedgingVault } from '../components/sections/HedgingVault'
import { Hero } from '../components/sections/Hero'
import { Integrations } from '../components/sections/Integrations'
import { Security } from '../components/sections/Security'
import { TradeOptions } from '../components/sections/TradeOptions'
import { Footer } from '../components/shared/Footer'

const Home: NextPage = () => {

  return (
    <div className="flex flex-col min-h-screen">
      <Hero />
      <Footer />
    </div>
  )
}

export default Home
